*{
margin: 0;
padding: 0;
box-sizing: border-box;
}
/* Sidebar styles */
.side-container{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 69px; 
    left: 0;
    width: 90px;
    height: calc(100% - 60px); 
    background-color: #238741; 
    border-right: 1px solid #dee2e6;
    overflow-y: auto;
    padding: 1rem;
    transition: transform 0.5s ease-in-out;
    transform: translateX(-100%);
}
.sidebar-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px; 
    left: 0;
    width: 200px;
    height: calc(100% - 60px); 
    background-color: #238741;
    border-right: 1px solid #dee2e6;
    box-shadow: '0px 0px 10px rgba(0, 0, 10, 0.2)';
    overflow-y: auto;
    padding: 1rem;
    /* transition: transform 0.3s ease-in-out; */
    /* transform: translateX(-100%); */
}
.sidebar-container.visible,
.side-container.visible {
    transform: translateX(0);
}
.sidebar-container::-webkit-scrollbar,
.side-container::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge, and Firefox */
.sidebar-container,
.side-container {
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}
@media (max-width: 980px) {
    .sidebar-container,
    .side-container {
        transform: translateX(-100%);
    }
    .sidebar-container.visible,
    .side-container.visible {
        transform: translateX(0);
    }
    .main-content.sidebar-open {
        margin-left: 200px;
    }
}
.table-responsive {
    margin-left: 180px;
    max-width: 100%;
  }
  
  @media (max-width: 700px) {
    .table-responsive {
      margin-left: 0;
    }
  }
/* Dashboard styles */
.upper-Hero, .middle-hero {
display: flex;
justify-content: space-between;
align-items: center;
padding: 7px;
background-color: #ffffff; /* Adjust as necessary */
}
/* Example of card styles */
.card{
background-color: rgba(18, 18, 203, 0.578);
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
margin: 10px;
}
.dashboard-container {
    width: calc(100% - 200px);
    margin-left: 200px;
    padding: 10px;
}
.nav-link{
    color: #4e9b74;
}
.nav-link:hover{
    color: red;
}
@media (max-width: 980px) {
    .dashboard-container {
        width: 90%;
        margin: auto;
        padding: 10px;
    }
}

.table-container{
    width: calc(100% - 240px);
    margin-left: 230px;
}

@media (max-width: 980px) {
    .table-container{
        width: 100%;
        margin-left: 10px;
        
    }
}

@media (max-width: 980px) {
    .custom-table {
        margin-left: 0 !important;
    }
}
/* Add these styles to your CSS file */
/* Add these styles to your CSS file */
@media (max-width: 980px) {
    .container {
    margin-left: 0 !important;
    }

    .table-responsive {
    overflow-x: auto;
    }
}


