/* StudentCount.css */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-content {
    padding: 20px;
    overflow: hidden;
}

.table-responsive {
    overflow-x: auto;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.thead-dark th {
    background-color: #343a40;
    color: #fff;
}

.pagination {
    margin-top: 20px;
}

.alert-info {
    margin-bottom: 20px;
}
